<template>
  <KumulatifTargetPasokanForm mode="Tambah" module="Data Kumulatif Target Pasokan"> </KumulatifTargetPasokanForm>
</template>

<script>
import KumulatifTargetPasokanForm from './form';

const KumulatifTargetPasokanAdd = {
  name: 'KumulatifTargetPasokanAdd',
  components: { KumulatifTargetPasokanForm },
};

export default KumulatifTargetPasokanAdd;
</script>
